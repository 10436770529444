import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import WebriqForm from "../components/form/form"

import Fb from "../images/contact/facebook.png"
import Linkedin from "../images/contact/linkedin.png"
import Twitter from "../images/contact/twitter.png"
import Instagram from "../images/contact/instagram.png"

const ContactUs = () => {
  return (
    <Layout page="contact-us">
      <Container fluid className="contact-page">
        <Container>
          <h1 className="text-center contact-flair mb-5 text-blue">
            CONTACT US
          </h1>

          <Row style={{ paddingTop: "50px" }}>
            <Col lg="6">
              {" "}
              <WebriqForm
                name="Contact Form"
                className="webriq-form"
                form_id="8bb942d6-22ee-4f1c-9a02-f8d49de8fa27"
                data_thankyou_url="/thank-you"
              >
                <div className="form-group mt-0">
                  <input
                    name="Email Address"
                    className="form-control"
                    id="email"
                    type="email"
                  />
                  <label htmlFor="phone">Your Email Address</label>
                </div>

                <Row>
                  <Col lg="6">
                    <div className="form-group mt-3 mb-0">
                      <input
                        name="Firstname"
                        className="form-control"
                        type="text"
                        id="firstName"
                        required=""
                      />

                      <label htmlFor="firstName">Firstname</label>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group mt-3 mb-0">
                      <input
                        name="Lastname"
                        className="form-control"
                        type="text"
                        id="lastname"
                        required=""
                      />

                      <label htmlFor="firstName">Lastname</label>
                    </div>
                  </Col>
                </Row>

                <div className="form-group mt-3">
                  <input
                    name="Phone Number"
                    className="form-control"
                    id="phone"
                    type="phone"
                  />
                  {/* <p className="opt-in-note ml-1" style={{ fontSize: "14px" }}>
                    You will not receive any SMS messages from us unless you
                    verbally opt-in.
                  </p> */}
                  <label htmlFor="phone">Phone Number</label>
                </div>
                <div className="form-group mt-4">
                  <textarea
                    name="Message"
                    className="form-control h-auto"
                    id="message"
                    required=""
                    rows="6"
                    cols="50"
                  />
                  <label htmlFor="message">Message</label>
                </div>
                <div className="form-group mt-3">
                  <div className="webriq-recaptcha" />
                </div>
                <div className="form-group mt-3 mt-0">
                  <button
                    className="common-btn m-0"
                    type="submit"
                    style={{ border: "none" }}
                  >
                    SUBMIT
                  </button>
                </div>
                <div className="form-group mt-4 smsCheckbox">
                  <input
                    type="checkbox"
                    id="SMSMessage"
                    name="SMS Message from PeerSource"
                    value="Yes"
                  />
                  <label htmlFor="SMSMessage">
                    By checking this box, you agree to receive SMS messages from
                    PeerSource. You can reply STOP to opt out at any time.
                    Standard SMS fees and Privacy Policy may apply. Note that
                    checking this box is not required to submit a message.
                  </label>
                </div>
              </WebriqForm>
            </Col>
            <Col lg="6">
              <div className="text-right">
                <p style={{ fontWeight: "900", color: "#176588" }}>
                  We're located in the Denver Tech Center south of <br />
                  Denver, CO at:{" "}
                </p>
                <p>
                  10303 E. Dry Creek Road, Suite 110 <br /> Englewood, CO 80112
                </p>
                <br />
                <a href="tel:3038671111">P: 303-867-1111</a>
                <br />
                <a href="fax:3033283531">F: 303-328-3531</a>
              </div>

              <iframe
                className="mt-5"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3074.9780304842725!2d-104.87176022352344!3d39.58264550598117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c85dd5a00712b%3A0x6f61d5ed7f792b7e!2s10303%20E%20Dry%20Creek%20Rd%20%23%20110%2C%20Englewood%2C%20CO%2080112%2C%20USA!5e0!3m2!1sen!2sph!4v1729654971510!5m2!1sen!2sph"
                width="100%"
                height="250"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                title="Map"
              ></iframe>
              <a
                href="https://goo.gl/maps/FB9aXQUpV8kZ1tLx9"
                className="common-btn text-left"
                style={{ border: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                VIEW LARGER MAP
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        className="social-links"
        fluid
        style={{ backgroundColor: "#0D2E4F" }}
      >
        <Container style={{ padding: "150px 0" }}>
          {" "}
          <Row className="align-items-center">
            <Col lg="5">
              <h2 className="text-white contact-flair2 mb-4">
                Connect with us
              </h2>
              <p className="text-white mt-4">
                Follow us on LinkedIn, Facebook, Twitter, and Instagram.
              </p>
            </Col>
            <Col lg="7">
              <Row className="text-center">
                <Col lg="3 my-2" xs={3}>
                  {" "}
                  <a
                    href="http://www.linkedin.com/company/peersource"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ width: "9em" }}
                      className="img-fluid"
                      src={Linkedin}
                      alt="Linkedin"
                    />
                  </a>
                </Col>
                <Col lg="3 my-2" xs={3}>
                  {" "}
                  <a
                    href="https://www.facebook.com/PeerSource/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ width: "9em" }}
                      className="img-fluid"
                      src={Fb}
                      alt="Facebook"
                    />
                  </a>
                </Col>
                <Col lg="3 my-2" xs={3}>
                  {" "}
                  <a
                    href="https://twitter.com/mypeersource"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ width: "9em" }}
                      className="img-fluid"
                      src={Twitter}
                      alt="Twitter"
                    />
                  </a>
                </Col>
                <Col lg="3 my-2" xs={3}>
                  {" "}
                  <a
                    href="https://www.instagram.com/mypeersource/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ width: "9em" }}
                      className="img-fluid"
                      src={Instagram}
                      alt="Instagram"
                    />
                  </a>
                </Col>
              </Row>
              <div className="d-flex justify-content-around"></div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="call-us-section" fluid>
        <Container>
          <h3 className="text-white">
            Give us a call today to find out how
            <br />
            PeerSource can help YOU.
          </h3>
          <p className="text-white">
            <em>We work across the entire US or staff in all 50 states.</em>
          </p>
          <a
            href="tel:303-867-1111"
            style={{ fontSize: "20px", color: "#00c1c8" }}
          >
            303-867-1111
          </a>
        </Container>
      </Container>
    </Layout>
  )
}

export default ContactUs
